import type { WrappedFieldArrayProps } from "redux-form";
import { Field } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { RichTextareaField } from "@js/forms/fields/rich-textarea";
import { required } from "@js/forms/validators";

import { FieldSectionPanelWithImportance } from "../fields/field-section-panel";

export const NewApplicationAnswersField = ({
  fields,
}: WrappedFieldArrayProps) => {
  return fields.map((item, index) => {
    const question = fields.get(index).question;

    return (
      <Field
        id={index}
        key={item}
        name={`${item}.answer`}
        component={SingleQuestionField}
        question={question}
        validate={[required]}
      />
    );
  });
};

const SingleQuestionField = ({ input, meta, question, id }) => {
  const displayError = !!meta.error && (meta.submitFailed || meta.touched);

  return (
    <FieldSectionPanelWithImportance
      key={id}
      title="Please answer this question from the Client"
      fieldImportance="required"
      className="job-bid-form__application-answer"
      isError={displayError}
    >
      <Typography
        htmlFor={`${question}-${id}-id`}
        aria-labelledby={question}
        component="label"
        variant="paragraph"
        size="large"
        mb={2}
      >
        {question}
      </Typography>
      {displayError && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {meta.error}
        </Typography>
      )}
      <RichTextareaField
        id={`${question}-${id}-id`}
        input={input}
        meta={meta}
        placeholder="Type your answer here"
        hideError
        required
        toolbar={[
          ["bold", "italic", "underline"],
          "format",
          ["align-left", "align-center", "align-right"],
          ["list", "indent"],
          ["emoji", "clear-formatting"],
          ["undo", "redo"],
        ]}
      />
    </FieldSectionPanelWithImportance>
  );
};
