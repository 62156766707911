import { EmployerEditWizard } from "@js/apps/employer/components/employer-edit-wizard";

import { ATSIntegrationViewLazy } from "../../lazy";

export const ATSIntegrationPage = () => {
  return (
    <EmployerEditWizard
      pageTitle="Ats integration"
      className="employer-team-members"
    >
      <ATSIntegrationViewLazy />
    </EmployerEditWizard>
  );
};
