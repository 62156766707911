export const PERSONAL_INFO_FORM_ID = "personalInfoForm";

export const ORGANIZATION_INFO_FORM_ID = "organizationInfoForm";

export const STATUS_COLORS = [
  {
    id: "green",
    label: "Green",
    color: "#e2f8e3",
  },
  {
    id: "yellow",
    label: "Yellow",
    color: "#feffb3",
  },
  {
    id: "red",
    label: "Red",
    color: "#f0989b",
  },
];

export const US_COUNTRY_CODE = "US";
export const FRONTEND_ADMIN_URL = SETTINGS.FRONTEND_ADMIN_URL;

export const BID_TYPES = {
  ALL: "all",
  HISTORICAL: "historical",
  CURRENT: "current",
} as const;

export const CUSTOM_JOB_LOCATIONS = Object.values(ENUMS.JobCustomLocationType)
  .map((location) => {
    return {
      type: "custom" as const,
      id: location,
      name: ENUMS.JobCustomLocationTypeLabels[location],
      custom: true,
    };
  })
  .reverse();

export const ACCESS_DENIED = {
  JOB_ACCESS_DENIED: "JOB_ACCESS_DENIED",
} as const;

export const REPORT_REASONS = [
  ...SETTINGS.REPORT_REASONS.map((reportReason) => ({
    label: reportReason,
    value: reportReason,
  })),
  {
    label: "Other",
    value: "Other",
  },
];

export const REPORT_POST_REASONS = [
  ...SETTINGS.REPORT_POST_REASONS.map((reason) => ({
    label: reason,
    value: reason,
  })),
  { label: "Other", value: "Other" },
];

export const HELP_OFFER_ACCEPTED_REPORT_REASONS = [
  {
    label: ENUMS.HelpOfferReportReasons.UNRESPONSIVE,
    value: ENUMS.HelpOfferReportReasons.UNRESPONSIVE,
  },
  ...REPORT_REASONS,
];

export const HELP_OFFER_REFUND_REQUESTED_REPORT_REASONS = [
  {
    label: ENUMS.HelpOfferReportReasons.NO_REFUND,
    value: ENUMS.HelpOfferReportReasons.NO_REFUND,
  },
  ...HELP_OFFER_ACCEPTED_REPORT_REASONS,
];

export const API_CONFIRM_ERROR_MESSAGE_SEARCH_PARAM =
  "confirmation_error_message";
